import "./AboutUs.scss";
import { Avatar, Col, Row } from "antd";

import React from "react";
import Button from "../../../../../../components/Button/Button";

import ABOUTUSCARD from "../../../../../../assets/images/default/about_us_card.jpg";
import ABOUTUSAVATAR from "../../../../../../assets/images/default/about_us_avatar.jpg";
import ABOUTUSSIGNATURE from "../../../../../../assets/images/default/about_us_signature.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const AboutUs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="about-us-component">
      <Row>
        <Col className="menu-containe-col" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <div className="about-us-container">
            <div
              className="about-us-image-container"
              style={{
                backgroundImage: `url(${ABOUTUSCARD})`,
              }}
            >
              <div className="years-expirience-container">
                <p className="years-number">25</p>
                <p>YEARS OF</p>
                <p>EXPIRIENCE</p>
              </div>
            </div>
            <div className="about-us-description-container">
              <h1 className="about-us-description-title">
                {t("home.aboutSection.title")}
              </h1>
              <p className="about-us-description-subtitle">
                “{t("home.aboutSection.subtitle")}”
              </p>
              <p className="about-us-description-text">
                {t("home.aboutSection.text")}
              </p>
              <ul className="about-us-description-list">
                <li>{t("home.aboutSection.list.item1")}</li>
                <li>{t("home.aboutSection.list.item2")}</li>
                <li>{t("home.aboutSection.list.item3")}</li>
              </ul>
              <div className="about-us-description-card">
                <div className="about-us-description-card-image">
                  <Avatar
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    src={<img src={ABOUTUSAVATAR} alt="avatar" />}
                  />
                </div>
                <div className="about-us-description-card-text">
                  <p className="about-us-description-card-text-title">
                    ER. BIDDEN HARRIS
                  </p>
                  <p>Director, Civil Engineer</p>
                </div>
                <div>
                  <img src={ABOUTUSSIGNATURE} alt="avatar" />
                </div>
              </div>
              <Button
                className="about-us-description-button"
                type="submit"
                onClick={() => {
                  navigate('/about');
                }}
                text={t("home.aboutSection.button")}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
