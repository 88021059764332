import { Col, Row } from "antd";
import "./Services.scss";

import React, { useEffect } from "react";
import ServicesCard from "../../../../../../components/ServicesCard/ServicesCard";
import SERVICE1 from "../../../../../../assets/images/services/service1.jpeg";
import SERVICE2 from "../../../../../../assets/images/services/service2.jpeg";
import SERVICE3 from "../../../../../../assets/images/services/service3.jpeg";

const Services = () => {
  console.log("SERVVICES CARD !!!!!");
  useEffect(() => {
    console.log("my first effect");
  }, []);
  const services = [
    {
      id: 1,
      title: "HEAVY CONSTRUCTION",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      image: SERVICE1,
    },
    {
      id: 2,
      title: "PROJECT PLANNING",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      image: SERVICE2,
    },
    {
      id: 3,
      title: "METAL ENGINEERING",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      image: SERVICE3,
    },
  ];
  return (
    <div className="services-component">
      <Row>
        <Col className="services-component-col" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <h1 className="services-component-title">OUR TOP NOTCH SERVICES</h1>
          <p className="services-component-subtitle">
            Mi nobis minima aliquet aspernatur repellat, justo voluptate eget
            tempor dictumst, doloremque modi blandit, massa. Voluptas numquam
            euismod, morbi ullamp.
          </p>
          <div className="services-container-card-holder">
            {services.map((item) => {
              console.log("sdadas => ", item);
              const data = item;
              return <ServicesCard key={item.id} service={data} />;
            })}
          </div> 
        </Col>
      </Row>
    </div>
  );
};

export default Services;
