import React from "react";
import Slider from "./components/Slider/Slider";
import AboutUs from "./components/AboutUs/AboutUs";
import Services from "./components/Services/Services";
import GalleryPreview from "./components/GalleryPreview/GalleryPreview";
import FAQ from "./components/FAQ/FAQ";
import Partners from "./components/Partners/Partners";

const HomePage = () => {
  return (
    <div>
      <Slider />
      <Partners />
      <AboutUs />
      <Services />
      <GalleryPreview />
      <FAQ />
    </div>
  );
};

export default HomePage;
