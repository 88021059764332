import "./PageHeader.scss";
import React from "react";

const PageHeader = ({ title, image }) => {
  return (
    <div className="page-header" style={{ backgroundImage:  `url(${image})` }}>
      <div className="page-header-overlay"></div>
      <div className="page-header-title">{title}</div>
    </div>
  );
};

export default PageHeader;
