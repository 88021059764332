import React from "react";
import { Routes, Route } from "react-router-dom";
import BasicHeader from "../components/BasicHeader/BasicHeader";
import BasicFooter from "../components/BasicFooter/BasicFooter";
import HomePage from "./pages/HomePage/HomePage";
import AboutUs from "./pages/AboutUsPage/AboutUsPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import GalleryPage from "./pages/GalleryPage/GalleryPage";
import AlbumPage from "./pages/AlbumPage/AlbumPage";

const MainView = () => {
  return (
    <div className="MainView">
      <BasicHeader />
      <div className="Content">
        <Routes>
          <Route path="*" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/gallery/:albumId" element={<AlbumPage />} />
        </Routes>
      </div>
      <BasicFooter />
    </div>
  );
};

export default MainView;
