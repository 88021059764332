import React, { useEffect } from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import HEADER from "../../../../assets/images/default/header_image.jpg";
import { useNavigate, useParams } from "react-router";

const AlbumPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const albumID = params.albumId;
  console.log("params albumID ==> ", params.albumId);

  useEffect(() => {
    if (params.albumId === "test1") {
      navigate("/");
    }
  }, [navigate, params.albumId]);

  const galleryPhotos = [
    { id: 1, url: "album 1 slika", album: "album1" },
    { id: 2, url: "album2 1", album: "album2" },
    { id: 3, url: "album1 2", album: "album1" },
    { id: 4, url: "album 2 2", album: "album2" },
    { id: 5, url: "aalbum3", album: "album3" },
  ];

  return (
    <div>
      <PageHeader title={params.albumId} image={HEADER} />
      {galleryPhotos
        ? galleryPhotos.map((album) => {
            if (album.album === albumID) {
              return (
                <div key={album.id}>
                  <p>{album.url}</p>
                </div>
              );
            } else {
              return null;
            }
          })
        : "Loading"}
    </div>
  );
};

export default AlbumPage;
