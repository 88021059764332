import "./HeadingElement.scss";

import React from "react";

const HeadingElement = ({ text, className }) => {
  const classes = ["heading-container", className];

  return <div className={classes.join(" ")}>{text}</div>;
};

export default HeadingElement;
