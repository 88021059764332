import { Col, Row } from "antd";
import "./Partners.scss";

import React from "react";
import AliceCarousel from "react-alice-carousel";

const Partners = () => {
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <img
      src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/06/builderon-img15-1.png"
      onDragStart={handleDragStart}
      role="presentation"
      alt="partner"
    />,
    <img
      src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/06/builderon-img14-1.png"
      onDragStart={handleDragStart}
      role="presentation"
      alt="partner"
    />,
    <img
      src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/06/builderon-img13-1.png"
      onDragStart={handleDragStart}
      role="presentation"
      alt="partner"
    />,
    <img
      src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/06/builderon-img12-1.png"
      onDragStart={handleDragStart}
      role="presentation"
      alt="partner"
    />,
    <img
      src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/06/builderon-img11-1.png"
      onDragStart={handleDragStart}
      role="presentation"
      alt="partner"
    />,
  ];

  const responsive = {
    0: {
      items: 3,
    },
    512: {
      items: 4,
    },
  };

  return (
    <div className="partners-main-container">
      <Row>
        <Col className="partners-container" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <AliceCarousel
            disableDotsControls
            disableButtonsControls
            mouseTracking
            items={items}
            responsive={responsive}
            autoPlay={true}
            autoPlayInterval={2000}
            infinite={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Partners;
