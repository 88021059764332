import { Col, Image, Row } from "antd";
import "./GalleryPreview.scss";

import React from 'react'
import Button from "../../../../../../components/Button/Button";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";


import SERVICE1 from "../../../../../../assets/images/services/service1.jpeg";
import SERVICE2 from "../../../../../../assets/images/services/service2.jpeg";
import SERVICE3 from "../../../../../../assets/images/services/service3.jpeg";
import { useNavigate } from "react-router";

const GalleryPreview = () => {
  const navigate = useNavigate();

  const userGalleryImages = [
    {
      id: 1,
      title: "HEAVY CONSTRUCTION",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      url: SERVICE1,
    },
    {
      id: 2,
      title: "PROJECT PLANNING",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      url: SERVICE2,
    },
    {
      id: 3,
      title: "METAL ENGINEERING",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      url: SERVICE3,
    },
    {
      id: 4,
      title: "HEAVY CONSTRUCTION",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      url: SERVICE1,
    },
    {
      id: 5,
      title: "PROJECT PLANNING",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      url: SERVICE2,
    },
    {
      id: 6,
      title: "METAL ENGINEERING",
      short_description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec",
      url: SERVICE3,
    },
  ];

  return (
    <div className="gallery-component">
      <Row>
        <Col className="gallery-component-col" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <h1 className="gallery-component-title">A GLANCE OF OUR WORK</h1>
          <p className="gallery-component-subtitle">
            Mi nobis minima aliquet aspernatur repellat, justo voluptate eget
            tempor dictumst, doloremque modi blandit, massa. Voluptas numquam
            euismod, morbi ullamp.
          </p>

          <div className="gallery-container-holder">
          <Image.PreviewGroup>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3 }}>
            <Masonry gutter="10px">
              {userGalleryImages?.map((data) => {
                return (
                  <div className="gallery-image-container" key={data.id}>
                    <Image src={data.url} />
                  </div>
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </Image.PreviewGroup>
          </div>
       
          <Button
            className="view-more-gaallery-button"
            type="submit"
            onClick={() => {
              navigate('/gallery');
            }}
            text="VIEW MORE PHOTOS"
          />
        </Col>
      </Row>
    </div>
  )
}

export default GalleryPreview