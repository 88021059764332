import "./BasicFooter.scss";

import { Col, Row } from "antd";
import React from "react";

import LOGO from "../../../assets/images/default/logo.png";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

const BasicFooter = () => {
  return (
    <div className="footer-container">
      <Row>
        <Col className="footer-component-col" span={16} offset={4}>
          <div className="footer-info">
            <div className="footer-logo-container">
              <img src={LOGO} alt="logo" />
            </div>
            <div className="footer-desc-container">
              Lorem ipsum dolor sit amet, cons aring elit sed dllao the eimod
              tempor inciunt ullaco laboris aliquip alora.
            </div>
            <div className="footer-social-container">
              {" "}
              <FacebookOutlined />
              <InstagramOutlined />
              <LinkedinOutlined />
            </div>
          </div>
          <div className="footer-contact">
            <div className="footer-contact-title">CONTACT INFORMATION</div>
            <div className="footer-contact-data">Phone: +1(456)657-887</div>
            <div className="footer-contact-data">Mail: info@domain.com</div>
            <div className="footer-contact-data">
              Address: 3557 Derek Drive, Orlando, Florida
            </div>
          </div>
          <div className="footer-links">
            {" "}
            <div className="footer-link-title">USEFUL LINKS</div>
            <div className="footer-link-data"><a href="/about">About us</a></div>
            <div className="footer-link-data"><a href="/about">Services</a></div>
            <div className="footer-link-data"><a href="/about">Contact</a></div>
          </div>
          <div className="footer-gallery"></div>
        </Col>
      </Row>
    </div>
  );
};

export default BasicFooter;
