import PageHeader from "../../../../components/PageHeader/PageHeader";
import HEADER from "../../../../assets/images/default/header_image.jpg";
import { useNavigate } from "react-router";

const GalleryPage = () => {
  const navigate = useNavigate();

  const albums = [
    {
      id: 1,
      title: 'Album 1',
      url: 'album1',
      cover_photo: "src",
    }, 
    {
      id: 2,
      title: 'Album 2',
      url: 'album2',
      cover_photo: "src",
    }, 
    {
      id: 3,
      title: 'Album 3',
      url: 'album3',
      cover_photo: "src",
    }, 
  ]

  return <div><PageHeader title={"GALLERY"} image={HEADER} />
  {albums ? albums.map((album) => {
    return (<div key={album.id} onClick={() => navigate(album.url)}>
          <p>{album.title}</p>
       </div>)
  }) : 'Loading'}
  
  </div>;
};

export default GalleryPage;
