import { Carousel } from "antd";
import "./Slider.scss";

import React from "react";
import Button from "../../../../../../components/Button/Button";
import { useNavigate } from "react-router";

const Slider = () => {
  const navigate = useNavigate();

  return (
    <div className="carousel-container">
      <Carousel
        className="carousel-main-container"
        dotPosition="right"
        effect="fade"
        // autoplay={true}
        // autoplaySpeed={5000}
      >
        <div className="carousel-slide-container">
          <div className="carousel-overlay"></div>
          <img
            alt="img"
            src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/07/builderon-img01.jpg"
          />
          <div className="carousel-description-container slide1">
            <div className="carousel-description-title">
              Build your future project
            </div>
            <div className="carousel-description-subtitle">
              Officiis architecto at nonummy blanditiis pulvinar dui maxime,
              netus, tempus voluptatibus rerum? Nobis architecto, metus cubilia,
              sem cumque. Illo etiam, blanditiis! Euismod gravida imperdiet!
              Platea reiciendis, ullam. Accusantium massa corrupti proident
              laudantium metus inceptos consequat? Sit cursus.
            </div>
            <Button
              className="about-us-description-button"
              type="submit"
              onClick={() => {
                navigate("/about");
              }}
              text="Learn More"
            />
          </div>
        </div>
        <div className="carousel-slide-container">
          <div className="carousel-overlay"></div>
          <img
            alt="img"
            src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/07/builderon-img02.jpg"
          />
          <div className="carousel-description-container slide2">
            <div className="carousel-description-title">
              Build your future project
            </div>
            <div className="carousel-description-subtitle">
              Officiis architecto at nonummy blanditiis pulvinar dui maxime,
              netus, tempus voluptatibus rerum? Nobis architecto, metus cubilia,
              sem cumque. Illo etiam, blanditiis! Euismod gravida imperdiet!
              Platea reiciendis, ullam. Accusantium massa corrupti proident
              laudantium metus inceptos consequat? Sit cursus.
            </div>
            <Button
              className="about-us-description-button"
              type="submit"
              onClick={() => {
                navigate("/about");
              }}
              text="Learn More"
            />
          </div>
        </div>
        <div className="carousel-slide-container">
          <div className="carousel-overlay"></div>
          <img
            alt="img"
            src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/07/builderon-img01.jpg"
          />
          <div className="carousel-description-container slide3">
            <div className="carousel-description-title">
              Build your future project
            </div>
            <div className="carousel-description-subtitle">
              Officiis architecto at nonummy blanditiis pulvinar dui maxime,
              netus, tempus voluptatibus rerum? Nobis architecto, metus cubilia,
              sem cumque. Illo etiam, blanditiis! Euismod gravida imperdiet!
              Platea reiciendis, ullam. Accusantium massa corrupti proident
              laudantium metus inceptos consequat? Sit cursus.
            </div>
            <Button
              className="about-us-description-button"
              type="submit"
              onClick={() => {
                navigate("/about");
              }}
              text="Learn More"
            />
          </div>
        </div>
        <div className="carousel-slide-container">
          <div className="carousel-overlay"></div>
          <img
            alt="img"
            src="https://demo.bosathemes.com/builderon/wp-content/uploads/sites/4/2021/07/builderon-img02.jpg"
          />
          <div className="carousel-description-container slide4">
            <div className="carousel-description-title">
              Build your future project
            </div>
            <div className="carousel-description-subtitle">
              Officiis architecto at nonummy blanditiis pulvinar dui maxime,
              netus, tempus voluptatibus rerum? Nobis architecto, metus cubilia,
              sem cumque. Illo etiam, blanditiis! Euismod gravida imperdiet!
              Platea reiciendis, ullam. Accusantium massa corrupti proident
              laudantium metus inceptos consequat? Sit cursus.
            </div>
            <Button
              className="about-us-description-button"
              type="submit"
              onClick={() => {
                navigate("/about");
              }}
              text="Learn More"
            />
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
