import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: { 
      sr: {
        translation: {
          menu: {
            home: 'Početna',
            about: 'O Nama',
            contact: 'Kontakt',
            services: 'Usluge',
            gallery: 'Galerija'
          },
          home: {
            aboutSection: {
              title: 'KAKO SMO POSTALI NAJBOLJI MEĐU DRUGIMA?',
              subtitle: 'Izgradili smo prelepo sa najboljim mogućim materijalima i resursima',
              text: 'U vreme velikih nevolja, himenej drže oni. Rezultat Rutrumovog laskanja, telo ga optužuje da ga mrzi strelama! Na meti je i osvetoljubivost, ali greška velike želje zaposlenog. Čas je čitan po pravu istine, iako je rečeno da je odbačen. razvoj',
              list: {
                item1: 'Sistem kontrole kvaliteta, 100% garancija na zadovoljstvo',
                item2: 'Visoko profesionalno osoblje, tačni procesi testiranja',
                item3: 'Izrada bez premca, profesionalna i kvalifikovana',
              },
              button: "Više o nama"
            }
          }
        }
      },
      en: {
        translation: {
          menu: {
            home: 'Home',
            about: 'About Us',
            contact: 'Contact',
            services: 'Services',
            gallery: 'Gallery'
          },
          home: {
            aboutSection: {
              title: 'HOW WE BECAME BEST AMONG OTHERS?',
              subtitle: 'We Built Beautifully With The Best Possible Materials And Resources',
              text: 'Nibh maiores molestiae tempore hymenaeos tenetur. Rutrum blanditiis consequuntur, corporis in accusamus odit in, sagittis! Ultrices, sed error magnam molestie cupidatat etiam, iaculis. Lectus class iure veritatis, cumque repudiandae dictumst. development.',
              list: {
                item1: 'Quality Control System, 100% Satisfaction Guarantee',
                item2: 'Highly Professional Staff, Accurate Testing Processes',
                item3: 'Unrivalled workmanship, Professional and Qualified',
              },
              button: "More about us"
            }
          }
        }
      },
    }
  });

export default i18n;