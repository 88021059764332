import { RightOutlined } from "@ant-design/icons";
import "./FAQ.scss";

import { Col, Collapse, Row } from "antd";

import React from "react";
import ContactForm from "../../../../../../components/ContactForm/ContactForm";

const { Panel } = Collapse;

const FAQ = () => {

  return (
    <div className="faq-component">
      <Row>
        <Col className="menu-containe-col"  xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
          <div className="faq-main-container">
            <div className="faq-container">
              <div className="faq-title">
                CHECKOUT FREQUENTLY ASKED QUESTIONS
              </div>
              <div className="faq-description">
                Magna voluptatum dolorem! Dolores! Sociosqu commodo nobis
                imperdiet lacinia? Magni! Felis, elementum nobis imperdiet
                lacinia nobis imperdiet lacinia imperdiet lacinia nobis
                imperdiet lacinia nobis.
              </div>
              <div className="faq-collapse-container">
                <Collapse
                  accordion
                  expandIcon={({ isActive }) => (
                    <RightOutlined rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Panel header="HOW WE BECAME BEST AMONG OTHERS?" key="1">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                  </Panel>
                  <Panel header="WHY CHOOSE US FOR YOUR PROJECTS?" key="2">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                  </Panel>
                  <Panel header="WHAT WE OFFER TO YOU?" key="3">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                  </Panel>
                  <Panel header="HOW WE PROVIDE SERVICES FOR YOU?" key="4">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                  </Panel>
                  <Panel header="ARE WE AFFORDABLE TO HIRE?" key="5">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                      dapibus leo.
                    </p>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="faq-contact-main-container">
              <ContactForm
                title="ANY QUESTIONS? ASK US!!"
                buttonText="SUBMIT QUESTION"
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default FAQ;
