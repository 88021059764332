import React from 'react';
import './OffersCard.scss';
import { FundOutlined, BulbOutlined, StarOutlined } from '@ant-design/icons';

const OffersCard = ({ offer }) => {
  console.log('offer ==> ', offer);
  return (
    <div className='offer-holder'>
      <div className='icon-holder'>{offer.icon}</div>
      <div className='title-holder'>
        <h1>{offer.title}</h1>
      </div>
      <div className='description-holder'>
        <p>{offer.description}</p>
      </div>
    </div>
  );
};

export default OffersCard;
