import "./ContactInfo.scss";

import React from "react";

const ContactInfo = ({ key, title, icon, infoData, iconType }) => {
  return (
    <div className="contact-info" key={key}>
      <div className={`icon-container ${iconType}`}>{icon}</div>
      <div className="title-container">{title}</div>
      {infoData?.map((info) => (
        <div className="contact-info-data">{info}</div>
      ))}
    </div>
  );
};

export default ContactInfo;
