import "./HeaderInfo.scss";

import React from "react";

const HeaderInfo = (props) => {
  const {icon, title, subtitle} = props;
  
  return (
    <div className="header-info">
      <div className="header-icon-container">
        {icon}
      </div>
      <div className="header-info-container">
        <div className="header-info-container-title">{title}</div>
        <div className="header-info-container-subtitle">{subtitle}</div>
      </div>
    </div>
  );
};

export default HeaderInfo;
