import "./ContactForm.scss";
import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import Button from "../Button/Button";

const ContactForm = ({title, buttonText, className}) => {
  const [contactForm] = Form.useForm();
  const classes = ['contact-form-main-container', className];

  const onFinish = (value) => {
    console.log("value ==>", value);
  };

  return (
    <div className={classes.join(' ')}>
      {title ? <div className="faq-contact-title">{title}</div> : null}
      <div className="faq-contact-form-container">
        <Form
          name="contact-form"
          form={contactForm}
          onFinish={onFinish}
          className="contact-form"
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter Your Name",
              },
            ]}
          >
            <Input placeholder="Your Name *" size="large" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please enter Your E-mail",
              },
            ]}
          >
            <Input placeholder="Your E-mail *" size="large" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Please enter Your Phone",
              },
            ]}
          >
            <Input placeholder="Your Phone *" size="large" />
          </Form.Item>
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                message: "Please enter Your Company",
              },
            ]}
          >
            <Input placeholder="Your Company *" size="large" />
          </Form.Item>
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: "Please enter Your message",
              },
            ]}
          >
            <TextArea
              placeholder="Enter Your message *"
              autoSize={{ minRows: 7, maxRows: 7 }}
            />
          </Form.Item>
          <Button
            className="about-us-description-button"
            htmlType="submit"
            text={buttonText}
          />
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
