import "./ContactPage.scss";

import React from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";

import ABOUTUSCARD from "../../../../assets/images/default/about_us_card.jpg";
import HEADER from "../../../../assets/images/default/header_image.jpg";
import ContactForm from "../../../../components/ContactForm/ContactForm";
import { Col, Row } from "antd";
import HeadingElement from "../../../../components/HeadingElement/HeadingElement";
import ContactInfo from "../../../../components/ContactInfo/ContactInfo";
import {
  EnvironmentOutlined,
  FieldTimeOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const ContactPage = () => {

  const contactInfo = [
    {
      id: 1,
      title: "OUR LOCATION",
      info_data: [
        "60 east 65th Street",
        "New York City, USA",
        "NQ 110256, San Fransisco",
      ],
      icon: <EnvironmentOutlined />,
    },
    {
      id: 2,
      title: "WORKING TIME",
      info_data: ["Mon-Fri : 7AM-5PM", "Sat 9AM-3PM", "Sunday(Holiday)"],
      icon: <FieldTimeOutlined />,
      icon_type: "dark",
    },
    {
      id: 3,
      title: "OUR NUMBERS",
      info_data: ["+1 555 9365 688", "+1 658 5214 399", "+978(122) 365 22"],
      icon: <PhoneOutlined />,
    },
    {
      id: 4,
      title: "EMAIL ADDRESS",
      info_data: ["office@gmail.com", "name@company.com", "info@company.com"],
      icon: <MailOutlined />,
      icon_type: "dark",
    },
  ];

  return (
    <div className="contact-page">
      <PageHeader title={"CONTACT US"} image={HEADER} />
      <Row>
        <Col className="menu-containe-col" span={16} offset={4}>
          <div className="contact-form-container">
            <div
              className="contact-form-image-container"
              style={{
                backgroundImage: `url(${ABOUTUSCARD})`,
              }}
            >
              <div className="contact-form-image-text-container">
                <p className="image-text-title">
                  FEEL FREE TO CONTACT & HIRE US !!
                </p>
                <p className="image-text-subtitle">
                  Magna voluptatum dolorem! Dolores! Sociosqu commodo nobis
                  imperdiet lacinia? Magni! Felis, elementum nobis imperdiet
                  lacinia nobis imperdiet laciniap.
                </p>
              </div>
            </div>
            <div className="contact-page-form-message-container">
              <HeadingElement text="Contact us" />
              <div className="contact-page-form-title">
                GET IN TOUCH WITH US!
              </div>
              <div className="contact-page-form-subtitle">
                Magina voluptatum doloriem! Dolores! Sociosqii commodiin nobis
                imperdiet lacinia? Magni! Felis, elementum nobips.
              </div>
              <ContactForm buttonText="submit message" className="light" />
            </div>
          </div>
          <div className="contact-info-container">
            {contactInfo.map((item) => {
              return (
                <ContactInfo
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                  infoData={item.info_data}
                  iconType={item.icon_type}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactPage;
