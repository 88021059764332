import React from 'react';
import './WorkingProcessCard.scss';

const WorkingProcessCard = ({ process }) => {
  return (
    <div className={`working-process-card-container ${process.direction}`}>
      <div className='working-process-image-container'>
        <img src={process.img} alt={process.title} />
      </div>
      <div className='working-process-data-container'>
        <div className='working-process-info-container'>
          <h1>{process.title}</h1>
          <p>{process.description}</p>
        </div>
      </div>
    </div>
  );
};

export default WorkingProcessCard;
