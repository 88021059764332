import { RightCircleOutlined } from "@ant-design/icons";
import "./ServicesCard.scss";

import React from "react";

const ServicesCard = ({ service }) => {
  console.log("service ==> ", service);
  return (
    <div className="services-card-container">
      <div className="services-card-image-container">
        <img src={service.image} alt={service.title} />
      </div>
      <div className="services-card-data-container">
        <div className="services-card-info-container">
          <h1>{service.title}</h1>
          <p>{service.short_description}</p>
        </div>
        <div className="services-card-button-container">
          <a href="*" >LEARN MORE <RightCircleOutlined /></a>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
